<template>
  <div class="w-3/5  mx-auto my-20 py-5 bg-white rounded-md shadow-md">
    <div
      class="border-b border-gray-200 bg-white px-4 py-5 sm:px-6 flex justify-between items-center"
    >
      <h3 class="text-lg font-medium leading-6 text-gray-900">
        {{ quizName }}
      </h3>
      <button
        @click="
          closeQuiz(
            'Stop quiz',
            'Are you sure you want to stop this quiz? All your progress will be lost.',
            $t('continue'),
            $t('goBack')
          )
        "
        type="button"
        class="inline-flex items-center rounded-full border border-transparent bg-red-600 p-1 text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
      >
        <XIcon class="h-5 w-5" aria-hidden="true" />
      </button>
    </div>
    <div
      v-for="(question, idx) in data"
      :key="question.id"
      v-show="questionId == idx"
    >
      <div class="mx-20 my-5">
        <h1 class="text-xl font-medium">{{ question.quiz_group.name }}</h1>
        <h2 class="text-gray-600">Question {{ idx + 1 }}</h2>
        <div class="grid grid-cols-12 rounded-md">
          <div
            @click="idx > 0 ? selectStep(idx - 1) : ''"
            :class="[
              idx > 0
                ? 'body__button text-white cursor-pointer'
                : 'bg-gray-50 text-gray-700',
              'col-span-1 flex justify-center items-center rounded-l-md border-l border-t border-b',
            ]"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          </div>
          <div class="col-span-10 px-20 py-10 border quiz__card--height">
            <check-multiple-answers
              @quizReply="(...args) => setReplies(...args)"
              :type="question.questionType"
              :question="question.question"
              :answers="question.quiz_replies"
              :mandatory="question.mandatoryReply"
            />
          </div>
          <div
            @click="idx < data.length - 1 ? selectStep(idx + 1) : ''"
            :class="[
              idx < data.length - 1
                ? 'body__button text-white cursor-pointer'
                : 'bg-gray-50 text-gray-700',
              'col-span-1 flex justify-center items-center border-r border-t border-b rounded-r-md',
            ]"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="relative py-10">
        <div class="absolute inset-0 flex items-center" aria-hidden="true">
          <div class="w-full border-t border-gray-300" />
        </div>
        <div class="relative flex justify-center">
          <nav aria-label="Progress">
            <ol role="list" class="flex items-center">
              <li
                v-for="(step, stepIdx) in steps"
                :key="step.id"
                :class="[
                  stepIdx !== steps.length - 1 ? 'pr-8 sm:pr-20' : '',
                  'relative',
                ]"
                @click="selectStep(stepIdx)"
              >
                <template v-if="step.status === 'complete'">
                  <div
                    class="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div class="h-0.5 w-full body__button" />
                  </div>
                  <a
                    href="#"
                    class="relative flex h-8 w-8 items-center justify-center rounded-full body__button"
                  >
                    <CheckIcon class="h-5 w-5 text-white" aria-hidden="true" />
                  </a>
                </template>
                <template
                  v-else-if="step.status === 'current'"
                  condition="step.status === 'current'"
                >
                  <div
                    class="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div class="h-0.5 w-full bg-gray-200" />
                  </div>
                  <a
                    href="#"
                    class="relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-green-600 bg-white"
                    aria-current="step"
                  >
                    <span
                      class="h-2.5 w-2.5 rounded-full body__button"
                      aria-hidden="true"
                    />
                  </a>
                </template>
                <template v-else>
                  <div
                    class="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div class="h-0.5 w-full bg-gray-200" />
                  </div>
                  <a
                    href="#"
                    class="group relative flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400"
                  >
                    <span
                      class="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300"
                      aria-hidden="true"
                    />
                  </a>
                </template>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div class="flex justify-center" v-if="questionId == data.length - 1">
        <button
          @click="putQuiz()"
          class="capitalize rounded group relative w-full mx-20 flex justify-center py-2 px-4 border text-sm font-medium shadow-md text-white body__button focus:outline-none"
        >
          {{ $t("finish") }}
        </button>
      </div>
    </div>
  </div>
  <ask-confirmation-dialog
    ref="askConfirmationDialog"
  ></ask-confirmation-dialog>
</template>

<script>
import axios from "axios";
import {
  UserCircleIcon,
  CheckCircleIcon,
  XCircleIcon,
  XIcon,
  CheckIcon,
} from "@heroicons/vue/solid";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import CheckMultipleAnswers from "../../components/CheckMultipleAnswers.vue";
import AskConfirmationDialog from "../../components/AskConfirmationDialog.vue";

export default {
  props: ["id", "quizName", "uuid"],
  components: {
    UserCircleIcon,
    Loading,
    CheckCircleIcon,
    XCircleIcon,
    XIcon,
    CheckIcon,
    CheckMultipleAnswers,
    AskConfirmationDialog,
  },
  data() {
    return {
      questionId: 0,
      isLoading: false,
      fullPage: false,
      data: [],
      quizzes: [],
      quizReplies: {},
      steps: [],
    };
  },
  methods: {
    emitActive() {},
    async closeQuiz(title, confirmationMessage, confirmButton, goBack) {
      const r = await this.$refs.askConfirmationDialog.show({
        title: title,
        message: confirmationMessage,
        okButton: confirmButton,
        cancelButton: goBack,
        action: "delete",
      });
      if (r) {
        this.$router.push(`/training/quizzes`);
      }
    },
    setReplies(...args) {
      console.log("file: QuizDetail.vue ~ line 209 ~ setReplies ~ args", args);
      let flag = false;
      for (let index = 0; index < args[1].length; index++) {
        const element = args[1][index];
        if (element.checked) {
          flag = true;
        }
      }
      this.steps.map((res) => {
        res.questionId == args[0] && flag
          ? (res.status = "complete")
          : res.status === "complete" &&
            !flag &&
            res.questionId == args[1][0].quiz_question_id
          ? (res.status = "current")
          : (res.status = res.status);
      });
      this.quizReplies[args[0]] = args[1];
    },
    putQuiz() {
      let url = `${this.$cookie.getCookie(
        "API"
      )}/api/v1/training/quiz-evaluations/${this.uuid}`;
      let replies = {};
      for (const key in this.quizReplies) {
        if (Object.hasOwnProperty.call(this.quizReplies, key)) {
          const element = this.quizReplies[key];
          replies[key] = [];
          for (let index = 0; index < element.length; index++) {
            const quizReply = element[index];
            quizReply.checked ? replies[key].push(quizReply.id) : "";
          }
        }
      }
      let data = {
        replies,
      };
      console.log("file: QuizDetail.vue ~ line 201 ~ putQuiz ~ data", data);
      axios
        .put(url, data)
        .then((res) => {
          this.$router.push(`/training/quiz-results/${this.uuid}`);
        })
        .catch((error) => {
          this.errorHandling(error);
        });
    },
    getProgress(data) {
      let completedCount = 0;
      let completedPercentage = 0;
      if (data) {
        let objData = JSON.parse(data);
        let keys = Object.keys(objData);
        keys.forEach((res) => {
          objData[res].length > 0 || Object.keys(objData[res]).length > 0
            ? (completedCount = completedCount + 1)
            : (completedCount = completedCount);
        });
      }

      completedPercentage = (completedCount / 10) * 100;

      return completedPercentage;
    },
    selectStep(id) {
      this.steps.map((res) => {
        res.id == id && res.status !== "complete"
          ? (res.status = "current")
          : res.status !== "complete"
          ? (res.status = "upcoming")
          : "";
      });
      this.questionId = id;
    },
    setSteps(data) {
      this.steps = [];
      let obj = {};
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        obj = {};
        obj["id"] = index;
        obj["questionId"] = element.id;
        obj["status"] = index == 0 ? "current" : "upcoming";
        this.steps.push(obj);
      }
    },
    async getQuizData() {
      this.quizzes = [];
      try {
        const url = `api/v1/training/quiz-questions`;
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/${url}?quiz_id=${this.id}`
        );
        console.log(
          "file: QuizDetail.vue ~ line 162 ~ getQuizData ~ res",
          res.data
        );
        this.data = res.data;
        this.setSteps(res.data);
        // for (let index = 0; index < res.data.quiz_groups.length; index++) {
        //   const element = res.data.quiz_groups[index];
        //   let quiz = await this.getGroupData(element.id);
        //   this.quizzes.push(quiz);
        // }
      } catch (error) {
        this.errorHandling(error);
      }
    },
    async getGroupData(id) {
      console.log("file: QuizDetail.vue ~ line 171 ~ getGroupData ~ id", id);
      try {
        const url = `api/v1/training/quiz-groups/${id}`;
        const res = await axios.get(`${this.$cookie.getCookie("API")}/${url}`);
        return res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },
  },
  mounted() {
    this.getQuizData();
  },
  computed: {},
};
</script>

<style>
.quiz__card--height {
  min-height: 400px;
}
</style>
